/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import Moment from "react-moment";

import { getSubjectCategoryListActions, getSubjectBoardListActions, getSsubjectGroupIndividualListActions, getSubjectTypeListActions, getSubjectListPageNumberActions, getSingleSubjectListActions } from '../../../store/actions/SujectActions';

import { scholasticExamDetailsBoardAction, scholasticExamDetailsClassAction, scholasticExamDetailsSubjectAction, scholasticExamDetailsAccademicSessionAction, scholasticExamDetailsStausAction, competitiveExamDetailsTypeAction, competitiveExamDetailsClassAction, competitiveExamDetailsSubjectAction, competitiveExamDetailsAccademicSessionAction, competitiveExamDetailsStausAction, competitiveExamDetailsSubTypeAction } from '../../../store/actions/ExamDetailsAction';

import {
  elibraryContentListTypeAction,
  elibraryContentListCategoryAction,
  elibraryContentListBoardAction,
  elibraryContentListClassAction,
  elibraryContentListSubjectAction,
  elibraryContentListChapterAction,
  elibraryContentListStatusAction,
  elibraryContentListExamTypeAction
} from "../../../store/actions/ElibraryAction";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {

  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.auth.menuList);
  const [date, setDate] = useState(new Date());
  const [selectMenuId, setSelectMenuId] = useState('')
  const [selectLastMenu, setSelectLastMenu] = useState('')

  let history = useHistory();

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);


  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  // useEffect(() => {
  //   let path = window.location.pathname;
  //   path = path.split("/");
  //   path = path[path.length - 1];
  //   // let selectMenu = menuList.filter
  //   let filteredUsers = menuList.filter(item => item.menu_link === path);
  //   // console.log(">>>>>>>>>", filteredUsers[0].parent_menu);
  //   setSelectMenuId(filteredUsers[0].parent_menu)
  // }, []);

  /* useEffect(() => {
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    // let selectMenu = menuList.filter
    let filteredUsers = menuList.filter(item => item.menu_link === path);
    // console.log("filteredUsers---------", filteredUsers[0].parent_menu)
    // console.log(">>>>>>>>>", filteredUsers[0].parent_menu);
    // setSelectMenuId(filteredUsers[0].parent_menu)
    if (filteredUsers && filteredUsers[0]?.parent_menu === 0) {
      let target_elements = document.querySelectorAll('#menu_bar_id .mm-show');
      target_elements.forEach((i) => {
        i.classList.remove("mm-show");
        // i.querySelector('.has-arrow:after').style.transform="rotate(-225deg)";
        let parent = i.parentNode;
        let test = parent.querySelector('.has-arrow')
        var styles = window.getComputedStyle(test,':after')
        var content = styles['transform:"rotate(-225deg)'];
        // i.parentNode.querySelector('.has-arrow:after').style.transform="rotate(-225deg)"
        console.log("test-------", content)

        //         var element = document.querySelector(' … ');
        // var styles = window.getComputedStyle(element,':after')
        // var content = styles['content'];
      })

    }

  }, [window.location.pathname]); */


  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  if (path == "") {
    path = "dashboard"
    history.replace('/dashboard');
  }

  /// Active menu
  let deshBoard = ["dashboard"],
    userCreation = [
      "create-user",
      "admin-user-list",
      "content-developer-list",
      "student-list",
      "teacher-list",
      "update-admin",
      "update-content-developer",
      "update-student",
      "update-teacher",
      "content-validator-list",
      "language-validator-list",
      "content-designer-list",
    ],
    examCategory = ["exam-category", "category-list", "update-category"],
    examType = ["exam-type", "type-list", "update-type"],
    examSubtype = ["exam-subtype", "subtype-list", "update-subtype"],
    board = ["create-board", "board-list", "update-board"],
    schoolDetails = ["upload-school", "school-list", "update-school"],
    classStandard = ["create-class", "class-list", "update-class"],
    subjects = ["create-subject", "subject-list", "update-subject"],
    branch = ["create-branch", "branch-list", "update-branch"],
    chapter = ["create-chapter", "chapter-list", "update-chapter"],
    typeExam = [
      "create-type-of-exam",
      "type-of-exam-list",
      "update-type-of-exam",
    ],
    questionPattern = [
      "create-question-type",
      "question-type-list",
      "update-question-type",
    ],
    examDetails = [
      "create-scholastic-exam-details",
      "scholastic-exam-details-list",
      "update-scholastic-exam-details",
      "create-competitive-exam-details",
      "competitive-exam-details-list",
      "update-competitive-exam-details",
    ],
    examContent = [
      "exam-content",
      "exam-content-list",
      "add-question",
      "update-question",
      "question-error-log",
      "upload-question-bulk-image",
    ],
    timeConfiguration = ["time-configuration", "time-configuration-list"],
    examConfiguration = [
      "exam-configuration",
      "exam-configuration-list",
      "update-configuration-list",
    ],
    subscription = [
      "add-competetive-subscription",
      "add-scholastic-subscription",
      "competetive-subscription-list",
      "scholastic-subscription-list",
      "update-competetive-subscription",
      "update-scholastic-subscription",
      "library-subscription-list",
      "add-integrated-subscription",
      "add-integrated-subscription",
      "integrated-subscription-list",
      "update-integrated-subscription"
    ],
    elibrary = [
      "elibrary-upload-content",
      "elibrary-content-list",
      "elibrary-content-details",
      "elibrary-dashboard",
    ],
    assign = [
      "assign-developer",
      "assign-elibrary-list",
      "assign-question-list",
      "assign-elibrary-details",
      "assign-question-details-list",
      "assign-question-details",
    ],
    eventHistory = [
      "create-event-history",
      "event-history-list",
      "update-event-history",
    ],
    roleManagement = [
      "create-role",
      "role-list",
    ],
    advertisement = ["advertisement-list", "add-advertisement", "update-advertisement"],
    careerGuide = ["upload-career-guide"],
    dataBaseBackup = ["database-backup"],
    settings = ["settings", "terms-condition", "privacy-policy"],
    state = ["Create-Error-State", "Error-State-List", "update-Error-State"],
    feedbackQuestion = ["add-feedback-question", "feedback-question-list"];

  const functionCall = (id) => {
    // console.log("id---------", id, menuList.filter(x => x.id === id)[0]?.parent_menu)
    // menuList.filter(x => x.id === id)[0]?.parent_menu
    setSelectLastMenu(menuList.filter(x => x.id === id)[0]?.parent_menu)
    /* if (id == 28) {
      // subjectListFiltterValueReset()
    }
    if (id == 43) {
      // scholasticExamDetailsFilterListReset()
    }
    if (id == 45) {
      // competitiveExamDetailsFilterListReset()
    }
    if (id == 63) {
      // elibraryFilterListReset()
    } */
  }

  /* const subjectListFiltterValueReset = () => {
    dispatch(getSubjectListPageNumberActions(1))
    dispatch(getSsubjectGroupIndividualListActions(0))
    dispatch(getSubjectBoardListActions(0))
    dispatch(getSubjectTypeListActions(0))
    dispatch(getSubjectCategoryListActions(0))
  } */

  /* const scholasticExamDetailsFilterListReset = () => {
    dispatch(scholasticExamDetailsBoardAction(0))
    dispatch(scholasticExamDetailsClassAction(0))
    dispatch(scholasticExamDetailsSubjectAction(0))
    dispatch(scholasticExamDetailsAccademicSessionAction(0))
    dispatch(scholasticExamDetailsStausAction(''))
  } */

  /* const competitiveExamDetailsFilterListReset = () => {
    dispatch(competitiveExamDetailsTypeAction(0))
    dispatch(competitiveExamDetailsClassAction(0));
    dispatch(competitiveExamDetailsSubjectAction(0));
    dispatch(competitiveExamDetailsAccademicSessionAction(0));
    dispatch(competitiveExamDetailsStausAction(''))
    dispatch(competitiveExamDetailsSubTypeAction(0))
  } */

  /* const elibraryFilterListReset = () => {
    dispatch(elibraryContentListTypeAction(0))
    dispatch(elibraryContentListCategoryAction(0))
    dispatch(elibraryContentListBoardAction(0))
    dispatch(elibraryContentListExamTypeAction(0))
    dispatch(elibraryContentListStatusAction(''))
    dispatch(elibraryContentListClassAction(0))
    dispatch(elibraryContentListSubjectAction(0))
    dispatch(elibraryContentListChapterAction(0))
    dispatch(getSingleSubjectListActions([]))
  } */


  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      {/* {console.log("&*****", path)} */}
      <PerfectScrollbar className="dlabnav-scroll" id='menu_bar_id'>



        <MM className="metismenu" id="menu">
          {menuList.map((item, i) => {
            // { console.log("selectLastMenu------123------", selectLastMenu) }
            // { console.log("menuList------------", menuList.filter(x => x.menu_link === path)[0]?.parent_menu === 0 ? menuList.filter(x => x.menu_link === path)[0]?.id : menuList.filter(x => x.menu_link === path)[0]?.parent_menu === undefined ? selectLastMenu : menuList.filter(x => x.menu_link === path)[0]?.parent_menu) }
            if (item.parent_menu == 0) {
              return (
                // <li key={'parent' + i} className={`${item.menu_group.includes(path) ? "mm-active" : ""}`}>
                // <li key={'parent' + i} className={`${item.id === (menuList.filter(x => x.menu_link === path)[0]?.parent_menu === 0 ? menuList.filter(x => x.menu_link === path)[0]?.id : menuList.filter(x => x.menu_link === path)[0]?.parent_menu === undefined ? selectLastMenu : menuList.filter(x => x.menu_link === path)[0]?.parent_menu) ? "mm-active" : ""}`}>
                <li key={'parent' + i} className={`${item.menu_group.includes(path) ? "mm-active" : ""}`}>
                  <Link className={item.menu_link == '' ? "has-arrow" : "ai-icon"} to={item.menu_link == '' ? "#" : item.menu_link}>
                    <i className={item.icon}></i>
                    <span className="nav-text">{item.menu_name}{/* {">"}{item.id} */}</span>
                  </Link>
                  {item.menu_link == '' ?
                    <ul>
                      {menuList.map((data, j) => {
                        if (item.id == data.parent_menu) {
                          return (
                            <React.Fragment key={'child' + j}>
                              <li onClick={() => functionCall(data.id)} ><Link className={`${path === data.menu_link ? "mm-active" : ""}`} to={"/" + data.menu_link}>{data.menu_name}{/* {">>"}{data.id} */}</Link></li>
                            </React.Fragment>
                          );
                        }
                      })}
                    </ul>
                    : null}
                </li>
              )
            }

          })}

        </MM>
        <div className="copyright mt-5">
          <p>
            Crestest Learning Ventures LLP ©{" "}
            {
              <Moment format="YYYY" withTitle>{date}</Moment>
            }{" "}
            All Rights Reserved
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};


export default SideBar;
